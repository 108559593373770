.delete-account-wrapper
  display: flex
  justify-content: center
  align-items: center
  min-height: 100vh
  background-color: #f4f4f4

.delete-account
  padding: 20px
  max-width: 90%
  max-width: 600px
  background-color: #fff
  border-radius: 8px
  box-shadow: 0 2px 4px rgba(0,0,0,0.1)
  display: flex
  flex-direction: column
  align-items: center
  margin: 10px

  @media (min-width: 768px)
    padding: 40px

  h1
    color: #333
    margin-bottom: 1rem

  p
    line-height: 1.6
    color: #666
    text-align: center
    margin-bottom: 1rem

  .email-link
    margin: 20px 0
    a
      display: inline-block
      background-color: #007bff
      color: #fff
      padding: 10px 15px
      border-radius: 5px
      text-decoration: none
      font-weight: bold
      transition: background-color 0.3s, box-shadow 0.3s

      &:hover, &:focus
        background-color: #0056b3
        box-shadow: 0 4px 8px rgba(0,0,0,0.2)
        outline: none
