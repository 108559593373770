@use '../../styles/utils/breakpoint' as *

.account__content
    .title
        font-weight: 700
        font-size: 18px
        margin-bottom: 0.7em
    p
        font-size: 16px
        margin: 1.5em 0em
    span.title
        font-weight: 600
        color: var(--bg)
        margin-right: 10px
    .user__info
        padding: 2em
        flex: 0 0 33%
    .form__form hr
        margin-bottom: 1.5em
    .form__button
        width: auto
        display: block
        margin: auto
        font-size: 16px
    .account--password
        width: 100%
        margin: auto
        margin-top:1.5em
    @include breakpointMax('medium')
        .title
            font-size: 15px
            line-height: 1.5em
        .account
            display: block
        .account--password
            width:100%
        .form__button
            font-size: 14px

.account__delete
    text-align: center
    margin-top: 2em
    font-size: 14px
    p
        color: var(--text-color) // Use your default text color
        a
            text-decoration: underline // Color for the link
            &:hover
                color: var(--danger-color)