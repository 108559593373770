@use '../../styles/utils/breakpoint' as *

.fc-day-today
  background: transparent !important
  background: white !important

.fc-timegrid-slot-label
  background-color: white

.fc-timegrid-slot-label-frame
  transform: translate(0, -100%)
  background-color: white

.fc-timegrid-slot
  height: 40px !important

.fc-scrollgrid
  border: none !important

.fc-scrollgrid-sync-inner
  padding: .5em 0 2em .5em
  text-align: left

td[data-time="08:00:00"]
  color: white

.fc-theme-standard td 
  background-clip: padding-box !important

.fc .fc-timegrid-slot-minor
  border-top-style: solid
  
.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > *
  top: calc(var(--header-height) + 5px)

.fc-daygrid-dot-event .fc-event-title
  text-overflow: ellipsis

.fc-daygrid-event-harness
  pointer-events: none

.fc-daygrid-day-frame.fc-scrollgrid-sync-inner
  cursor: pointer

.fc-timegrid-event-harness
  cursor: pointer

.fc-header-toolbar
  flex-direction: column !important

  @include breakpoint('medium')
    flex-direction: row !important

.fc-col-header-cell.fc-resource
  vertical-align: middle
  .fc-scrollgrid-sync-inner
    text-align: center
    .fc-col-header-cell-cushion
      text-decoration: underline
      text-underline-offset: 4px
      cursor: pointer

.fc-toolbar-chunk div
  display: flex !important
  align-items: center

.fc .fc-toolbar-title
  padding: 0 .5em
  font-size: 14px

  @include breakpoint('medium')
    padding: 0
  
  @include breakpointMax('small')
    font-size: 18px

.fc-toolbar-chunk:nth-child(2)
  margin-bottom: 1em !important
  background-color: white
  div
    gap:8px
    border:1px solid var(--grey)
    border-width: 1px 0px 1px 0px
  h2
    color: #262628
    padding: 0px 12px
    &::before 
      content: '\f133'
      font-family: "Font Awesome 5 Free"
      color: var(--grey)
      font-size: 1.2em
      margin-right: 10px


  button
    background: white
    border-color: var(--grey)

    border-width: 0px 1px 0px 1px
    &:hover
      background: white
      opacity: 0.8
    span
      color: var(--grey)


  @include breakpoint('medium')
    margin-bottom: 0
.fc-v-event .fc-event-main
  overflow: hidden

.fc-timegrid-event-short .fc-event-title
    white-space: nowrap
    text-overflow: ellipsis
    max-width: 35%

@include breakpointMax('medium')
  .fc .fc-button
    font-size: .7em
  .fc-timegrid-event-short .fc-event-title
    max-width: 15%

.fc
  .fc-scrollgrid 
    background-color: #fff
    
  .fc-button-group
    
    .fc-button-primary.fc-button-active
      border-color: var(--blue)
      background-color: var(--blue)
      &:focus
        box-shadow: unset
  .fc-button-primary
    :focus
      box-shadow: unset
table 
    width: 100%


tbody td, thead th 
    width: auto

