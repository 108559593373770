@use '../../styles/utils/breakpoint' as *
  
.event-panel
  position: absolute
  z-index: 1
  background-color: white
  padding: 1.5em 2em
  box-shadow: 0px 0 38px 5px rgb(#000, .3)
  border-radius: 7px
  white-space: nowrap
  @include breakpointMax('medium')
    width: 100%

  &__actions
    color: #878A8D
    text-align: right
    margin-bottom: 1em

    i
      cursor: pointer

    i:not(:last-child)
      margin-right: .6em
    button
      border: unset
      background-color: white

  &__wrapper
    display: flex
    align-items: flex-start
    margin-bottom: .5em

    p
      margin: 16px 0px

  &__room-color
    width: 15px
    aspect-ratio: 1
    border-radius: 3px
    margin-right: 1em
    margin-top: .7em

  &__room-title
    font-weight: 500
    white-space: nowrap
    margin-bottom: .3em
    @include breakpointMax('medium')
      font-size: 15px
  
  &__close-button
    border: none
    background-color: var(--main-blue)
    color: white
    padding: 1em 2em
    border-radius: 4px
    margin-top: 2em
    cursor: pointer
    width: 100%
  &__description
    max-width: max-content
    line-height: 1.9em
    white-space: normal
  @include breakpointMax('medium')
    p
      font-size: 14px

  .box
      padding: 6px 9px
      background-color: #e6e6e6
      border-radius: 5px
      margin: 5px
  &--room
    width: 30%
    @include breakpointMax('medium')
      width: 100%
    p
       font-size: 16px
       white-space: initial
       line-height: 1.8em
    .title
        font-weight: 600
        font-size: 18px
        color: var(--bg)
  .room__title
    text-align: left
    font-weight: 700
    font-size: 20px
    color: #000
    
    &--office
      font-weight: 400
      font-size: 16px
  & .room__images
    display: flex
    margin-top:10px
    img
      width: 50%