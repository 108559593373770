@use '../utils/breakpoint' as *

*
    padding: 0
    margin: 0
    box-sizing: border-box

body
    font-family: 'Montserrat', sans-serif

a
    text-decoration: none
    color: inherit
    
.bg-grey
    background-color: #F2F2F4

.card
    background-color: #fff
    border-radius: 5px
    padding: 0.5em 0.5em 1em
    position: relative
.btn
    border: none
    font-family: 'Montserrat'
    border-radius: 4px
    padding: 0.9em 1.2em
    font-size: 1rem
    cursor: pointer
    font-size: 0.9rem
    width: 100%

    &.btn-primary
        background-color: var(--bg)
        color: white
    &.btn-secondary
        background-color: #fff
        color: #000
        border: 1px solid var(--bg)
        
.text-primary
    color: var(--bg)

.w-50
    width: 50%

.d-flex
    display: flex

@include breakpoint('medium')
    .col
            flex-basis: 0
            -webkit-box-flex: 1
            -ms-flex-positive: 1
            flex-grow: 1
            max-width: 100%
.justify-content-center
    justify-content: center

.text-center
    text-align: center

.mx-auto
    margin: auto

