.office
  padding: 1em 2.5em

  &__title
    display: flex
    justify-content: space-between
    cursor: pointer

  &__wrapper
    display: flex

  .fa-building
    margin-right: .8em

  .fa-angle-down
    transform: rotate(180deg)

  &__rooms-container
    height: 0
    overflow: hidden

  &--visible
    .fa-angle-down
      transform: rotate(0deg)

    .office__rooms-container
      height: auto
