.users-table
    background-color: #fff
    border-radius: 5px
    padding: .5em
    padding-bottom: 1em
    overflow-x: auto
    position: relative
    display: flex
    align-items: flex-end
    justify-content: flex-end
    flex-direction: column

    &::after
        content: ''
        display: none
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        background-color: #ffffff3b

    &--is-loading-next-page::after
        display: block

    &__button
        border: none
        cursor: pointer
        background-color: var(--main-blue)
        color: white
        border-radius: 5px
        padding: .5em 1em
        font-size: 1rem
        white-space: nowrap

        &:first-child
            margin-right: 1em

    table
        border-collapse: collapse
        min-width: 100%

    thead
        text-align: left

    tbody
        color: #737375

    td, th
        padding: .8em
        border-bottom: 1px solid #F2F2F4

    tr:last-child td
        border-bottom: none

    tr th
        font-weight: 400


.new_user
    max-width: 250px