.custom-link
    color: inherit
    text-decoration: none
    padding: 1em 2.5em
    cursor: pointer
    display: flex

    i
        margin-right: .5em

    &:hover,
    &--active
        background-color: #3B4452
