@use '../../styles/utils/breakpoint' as *

    
.ReactModal
    &__Overlay
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0

.ReactModal
    &__Overlay
        z-index: 1000
        background-color: rgb(2 2 2 / 0.2)
        overflow: scroll
    &__Content
        justify-content: center
        position: relative
        display: flex
        align-items: center
        text-align: center
        width: 40%
        margin: 10% auto 0 auto
        box-shadow: 0 1rem 3rem rgba(0,0,0,.175)
        @include breakpointMax('medium')
            width: 100%
        &:focus-visible
            outline: none
        &  .close
            position: absolute
            top: 30px
            font-size: 1.3em
            color: var(--bg)
            cursor: pointer
            right: 50px

.book,.modal-form
    width: 80%
    background-color: white
    padding: 20px 20px
    text-align: left
    .form__label
        font-weight: 500
    @include breakpointMax('medium')
        width: 90%
    &__headline
        margin-bottom: 1em
        font-weight: 500
        text-align: center
        @include breakpointMax('medium')
            font-size: 20px
    &__date
        padding: 8px 10px
        border: 1px solid var(--grey)
        border-radius: 10px
        i
            margin-left: 6px
            font-size: 12px

        @include breakpointMax('medium')
            font-size: 14px
    &__date-time-wrapper
        display: flex
        align-items: center

    &__clock-icon
        margin-right: 2em
        margin-left: 1em
    @include breakpointMax('medium')
        margin-right: .5em
        margin-left: .5em
    &__date-time-wrapper
        margin-bottom: 1.3em

    &__date-text
        margin-bottom: 1.5em !important
        margin-top: 1.5em

        

    &__date-str
        margin-right: 2em

    &__time
        position: relative
        cursor: pointer
        margin-left:5px
        padding: 8px 10px
        border: 1px solid var(--grey)
        border-radius: 10px
        display: flex
        align-items: center
        @include breakpointMax('medium')
            font-size: 14px
        i
            margin-left: 6px
            font-size: 12px

    &__time-separator
        margin: 0 .4em
    .form__button
        width: 30%
        margin: auto
        font-family: 'Montserrat'
        background-color: var( --bg)
        @include breakpointMax('medium')
            width: 50%
    .react-datepicker
        margin-top: 16px
    .react-datetime-picker__wrapper,
    .react-datepicker-wrapper,
    .react-datepicker__triangle
        display: none

    .react-datepicker-popper
        position: absolute
        transform: translate(-10px, 10px) !important
.date__labels
    &-wrapper
        display: flex
    &-date 
        width: 48%
.images
    margin-top:10px
    display: flex
    flex-wrap: wrap
    &__wrapper
        position: relative
    &__item
        width: 100px
        margin: 0px 5px
        height: auto
        cursor: pointer
    i
        position: absolute
        padding: 5px
        background-color: var(--bg)
        top: 5px
        font-size: 1.1em
        right: 10px
        color: #FFF
        cursor: pointer
.RoomModal
    margin: 5% auto 0 auto