@use '../../styles/utils/breakpoint' as *

.side-bar
    background-color: var(--bg)
    color: white
    height: 100vh
    padding: 2em 0
    position: absolute
    transform: translateX(-100%)
    z-index: 9099
    transition: transform 200ms
    min-width: 100vw
    &::-webkit-scrollbar
        width: 0px

    @include breakpoint('medium')
        min-width: 375px

    @include breakpoint('medium')
        position: fixed
        top: 0
        left: 0
        transform: translateX(0)
        overflow-y: auto
        scrollbar-width: none
        -ms-overflow-style: none
        &.md-sidebar--hide
            min-width: auto
            max-width: 70px
            .side-bar__links span,.office__wrapper div,.office i.fa-angle-down 
                display: none
            .side-bar__header a,.side-bar__header svg,.side-bar__header .side-bar__close
                display: none
            .side-bar__open
                display: block
            .custom-link,.office
                padding: 1em 1.5em
            .office--visible .office__rooms-container
                height: 0


    @include breakpointMax('medium')
        height: auto

    &--visible
        transform: translateX(0)
        box-shadow: 2px 0 5px 2px rgba(#000, .15)

        @include breakpoint('medium')
            transform: none
            box-shadow: none

    &__header
        display: flex
        align-items: center
        justify-content: center
        margin-bottom: 4em

    &__cosa-logo
        margin-right: 1em

    &__reservercosa
        font-size: 1.5rem
        font-weight: 700

        @include breakpoint('medium')
            margin-right: 0

    &__links
        list-style: none

        &--bottom
            border-top: 1px solid white
            padding-top: 1.3em
            margin-top: 150px
            
        @include breakpointMax('laptop-l')
            margin-top: 30px
       
        @include breakpointMax('medium')
            margin-top: 10px
            padding-top: 1em

    &__close
        margin-left: 2em
        cursor: pointer
    &__open
        display: none
        cursor: pointer

       
