@use '../utils/breakpoint' as *

// Material Toolbar 
.MuiToolbar-root
    .btn
        padding: 10px
        font-size: 17px
        &.btn-blue
            color: white
            background-color: var(--blue)
        i.fa.fa-plus
            font-size: 16px
            margin-right: 10px

    @include breakpointMax('small')
        .btn
            font-size: 15px
        i.fa.fa-plus
            display: none
    


    &.MuiToolbar-gutters
        background-color:  #F2F2F4
        min-height: 80px
        
        .MuiTextField-root
            background-color: white
            height:50px
            width: 40%
            padding-left: 0
        @include breakpointMax('small')
            .MuiTextField-root
                display: none   
            

    .MuiInputAdornment-positionStart
        margin-left:20px
        margin-right: 10px
        
.MuiTableBody-root
    .MuiFormControl-root
        width:100%
