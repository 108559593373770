.spinner
  text-align: center
  z-index: 1

  &--overlay
    color: #686868
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  &--to-top
    top: 30%

  i
    animation: spin 600ms linear infinite
    font-size: 2rem

@keyframes spin
  to
    transform: rotate(360deg)
