@use '../../styles/utils/breakpoint' as *

.cosavostra-meta
    padding: 0 1em

    &__app-name
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 1.2em

    &__logo
        margin-right: 1em

    &__made-with-love
        color: #7A7A7A

    &__description
        text-align: center
        margin-bottom: 2em
        line-height: 1.5em
