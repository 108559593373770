.toggle-approval
    width: 44px
    height: 24px
    border-radius: 30px
    background-color: #34C759
    position: relative
    cursor: pointer
    transition: background-color 200ms
    display: block

    &::after
        content: ''
        position: absolute
        height: calc(100% - 4px)
        aspect-ratio: 1
        border-radius: 50%
        background-color: #fff
        display: block
        top: 50%
        box-shadow: 0 0 4px 1px rgba(#000, .2)
        transform: translate(calc(100% + 2px), -50%)
        transition: transform 200ms

    &--off
        background-color: rgba(#787880,.16)

        &::after
            transform: translate(2px, -50%)
