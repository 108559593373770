@use '../../styles/utils/breakpoint' as *

.container
    position: relative

    &__content
        @include breakpoint('medium')
            display: flex
            flex-direction: column
            margin-left: var(--side-bar-width)

    &__content-wrapper
        padding: 3em 2em 3em
        flex-grow: 1
        padding-top: 120px
        position: relative
        min-height: 100vh
        height: auto
