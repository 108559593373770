.room
  margin-left: 2em
  margin-bottom: 1em
  display: flex
  align-items: center
  user-select: none
  cursor: pointer

  &:first-child
    margin-top: 1em

  input[type='checkbox']
    display: none

  &__checkbox
    background-color: transparent
    border-radius: 3px
    width: 17px
    aspect-ratio: 1
    display: flex
    align-items: center
    justify-content: center
    margin-right: .7em
    cursor: pointer

    i
      font-size: .7rem
      display: none
      color: white
