@use '../../styles/utils/breakpoint' as *

.header
    display: flex
    align-items: center
    justify-content: space-between
    padding: 1em 2em
    box-shadow: 0 2px 2px -1px rgba(#000, .2)
    position: fixed
    left: var(--side-bar-width)
    right: 0
    max-width: 100%
    background-color: white
    z-index: 2

    @include breakpointMax('medium')
        left:0

    &__book-button
        background-color: var(--bg)
        border: none
        padding: 0.8em 1.3em 0.8em 1.3em
        color: white
        border-radius: 4px
        font-size: 1rem
        line-height: 140%
        cursor: pointer
        i
            margin-right: 10px
            font-size: 1.2em
            

    &__sync-google
        display: none
        border-radius: 8px
        box-shadow: 0 0 5px -1px rgba(#000, .15)
        padding: .5em .8em
        cursor: pointer

        @include breakpoint('medium')
            display: flex
            align-items: center
            justify-content: center

        svg
            margin-right: 1em
    &__sync-button
        outline: 0
        padding: 5px
        background-color: white
        color: black
        border: unset
        width: 100%
        cursor: pointer

    &__bars
        font-size: 1.5rem
        cursor: pointer

        @include breakpoint('medium')
            display: none
